<template>
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-12 col-sm-12">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ getTranslation('error') }} 404</h2>
          </div>
          <p>{{ getTranslation('error404') }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: "error404"
}
</script>

<style scoped>

</style>